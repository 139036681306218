@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Caladea&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
*>*{
  font-family: 'Caladea', serif;
}

body {
  margin: 0;
  padding: 0%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.checking {
 
  background-color: #ffffff;
  color: black;
  font-size: 16px;
 
 }
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
 
 
