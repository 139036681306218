.visibility{
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 1px;
}
.inputfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #346448;
  }
/* .inputfield .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #346448;
  } */
  /* .inputfield .MuiFilledInput-root .MuiFilledInput-underline {
    border-bottom-color: #346448;
  } */
  .selectfield{
    border-radius: 10px;
  }