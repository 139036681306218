/* AddFood.css */

.addFoodContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  padding: 2%;
  margin-bottom: 20px; /* Add margin to bottom to remove extra space */
}

.addFoodBox {
  overflow-x: hidden;
}

.addFoodGrid {
  margin-left: 0.2rem;
  margin-top: 1rem;
}

.addFoodTitle {
  font-size: 30px;
  color: #38A389;
  margin-left: 31px;
  font-weight: bold;
}

.addFoodForm {
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.addFoodErrorMessage {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.addFoodButton {
  margin-bottom: -30px;
  text-align: center; /* Align button to the center */
}

.addFoodSuccessMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-bottom: 10px;
}
