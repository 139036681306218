.slick-next {
    /* Change to your desired color */
    color: black; /* Change to your desired text color */
  }
  
  .slick-prev {
    /* Change to your desired color */
    color: purple; /* Change to your desired text color */
  }
  .slick-prev::before,
.slick-next::before {
  color: #A87BD4; /* Change to your desired color */
}
  .imagesize{
  border-radius: 50%;
  object-fit: cover;
  margin: auto;
  width: 5.5em;
  height: 5.5em;
  background-color: white;
  padding: 12px;
  }
  .buttondesign{
    border-radius: 50%;
    width: 2em;
    height: 2em;
    background-color:'white';
  }
  .flip-card {
    background-color: white;
    width: 13rem;
    height: 9rem;
    perspective: 1000px;
    /* margin-right: 0.7em; */
    margin-top: 1em;
  }
  
  .flip-card-inner {
    position: relative;
    /* width: 100%;
    height: 100%; */
    background-color: #A87BD4;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-back {
    position: absolute;
    /* background-color: #2980b9; */
    width: 100%;
    height: 100%;
    margin-top: 17px;
    padding-top: 5px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    background-color: #A87BD4;
    color: black;
    transform: rotateY(180deg);
  }