.foodDetails{

    margin-left: 5rem;
    margin-top: 2rem;
}
.itemDiv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }
  
  .itemDiv .MuiTypography-root {
    flex: 1;
    margin-right: 10px;
  }
  
  .itemDiv .MuiTypography-root:last-child {
    margin-right: 0;
  }
  